
import React from 'react';
import Hero from 'blocks/Hero';
import Text from 'blocks/Text';
import Image from 'blocks/Image';
import Columns from 'blocks/Columns';
import AtmosphericImage from 'blocks/AtmosphericImage';
import Grid from 'blocks/Grid';
import Layout from 'blocks/Layout';
import BlogTeaser from 'blocks/BlogTeaser';
import Default from 'blocks/Default';

const availableBlocks = {
  'Hero': Hero,
'Text': Text,
'Image': Image,
'Columns': Columns,
'AtmosphericImage': AtmosphericImage,
'Grid': Grid,
'Layout': Layout,
'BlogTeaser': BlogTeaser,
};

const Blocks = ({block}) => {
    const blockName = block.__typename.replace(/.*_([a-z]*)$/gi, '$1');

    return availableBlocks[blockName]
        ?  React.createElement(availableBlocks[blockName], {Blocks, ...block})
        : <Default blockType={blockName}/>;
};

export default Blocks;
